import React, {Component} from 'react';
import {Product, ProductMapping, Selection} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {FormatMoney} from "./format_money";

interface Props extends IGlobalDataContext {
    selection: Selection
    approved: boolean
    productMappings: Array<ProductMapping>

    approve()
}

interface States {

}

class CustomerSelectionRaw extends Component<Props, States> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        let selectedProduct: Product = {
            shelftimeAlert: null, shelftimeNotice: null,
            hidden: false,
            placeholder: false,
            id: '',
            uuid: '',
            name: '',
            imageUploadKey: null,
            productCategory: null,
            productUnit:'',
            productType:'',
            formulas:null,
            source:'VOT',
            category:"",
            nutrition:"",
            displayPriority: 0,
            attribute1: '',
            attribute2: '',
            attribute3: '',
            attribute4: '',
        };
        this.props.productMappings.forEach((productMapping) => {
            if (productMapping.code === this.props.selection.selection) {
                const x = this.props.findProduct(productMapping.product_id);
                if (x) {
                    selectedProduct = x;
                }
            }
        });

        return (
            <div>
                <h3>Customer selection</h3>

                <div>Selection: {this.props.selection.selection}</div>

                <div>Product: {selectedProduct ? selectedProduct.name : '-'}</div>
                <div>Price: <FormatMoney cents={this.props.selection.cents}/></div>
                <hr/>
                <button disabled={this.props.approved} className="btn btn-success"
                        onClick={this.props.approve}>Approve
                </button>
            </div>
        );
    }

}

const CustomerSelection = withGlobalData(CustomerSelectionRaw);
export default CustomerSelection;
