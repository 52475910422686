import React, { Component } from 'react';
import { Category, Message, productPicture } from "./models";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import { getAPI, postAPI, ProductServiceAttributeApi } from "./api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"
import { V1GetProductAttributesResponse } from './telemetry/vending_machine/v1/typescript-axios';

interface Props extends IGlobalDataContext {
    match: any
}

interface State {
    id: string
    uuid: string
    name: string
    shelftimeAlert: number | null
    shelftimeNotice: number | null
    pictureFile: File | null
    pictureUrl: string
    selectedCategory: number
    categoriesList: Category[]
    formulaList
    nutrition: string
    displayPriority: number
    attribute: V1GetProductAttributesResponse | null
}

class ProductDetailRaw extends Component<Props, State> {
    private pictureEncodedContent: string | null = null;

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            uuid: "",
            name: "",
            shelftimeAlert: 0,
            shelftimeNotice: 0,
            pictureFile: null,
            pictureUrl: '',
            selectedCategory: -1,
            categoriesList: [],
            formulaList: [],
            nutrition: "",
            displayPriority: 0,
            attribute: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.allProducts !== prevProps.allProducts) {
            console.log('did update')
            this.setProductDetail()
        }
    }

    componentDidMount(): void {
        console.log('did mount');
        getAPI(`/api/categories`).then((response) => {
            if (response.data.success) {
                this.setState({ categoriesList: response.data.productCategories });
            } else {
                alert(response.data.message);
            }
        })

        const product = this.props.findProduct(this.props.match.params.id);

        if (product?.id === this.state.id) {
            return;
        }
        console.log('product uuid', product?.uuid)
        new ProductServiceAttributeApi().productServiceGetProductAttributes(product?.uuid ?? '').then(
            res => {
                this.setState({
                    attribute: res.data
                })
            }).catch((e) => {
                alert("get Attributes error " + e)
            });

        this.setProductDetail()
    }



    setProductDetail() {
        const product = this.props.findProduct(this.props.match.params.id);

        if (product?.id === this.state.id) {
            return;
        }

        if (product) {
            this.setState({
                name: product.name,
                shelftimeAlert: product.shelftimeAlert,
                shelftimeNotice: product.shelftimeNotice,
                id: product.id,
                uuid: product.uuid,
                pictureUrl: productPicture(product),
                nutrition: product.nutrition,
                displayPriority: product.displayPriority,
            })
            if (product.productCategory) {
                this.setState({ selectedCategory: product.productCategory })
            }
            if (product.formulas) {
                this.setState({ formulaList: product.formulas }, () => {
                    console.log(this.state.formulaList)
                })
            }
        } else {
            // window.alert("Product not found")
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        let ps: Array<Promise<any>> = [];

        if (this.pictureEncodedContent) {
            ps.push(postAPI(`/api/products/${this.state.id}/picture`, { imageContent: this.pictureEncodedContent }).then((response) => {
                if (response.data.success) {
                    console.log('picture uploaded.');
                } else {
                    window.alert(response.data.message);
                }

                return;
            }));
        }

        if (this.state.attribute) {
            new ProductServiceAttributeApi().productServiceUpdateProductAttributes(this.state.uuid, this.state.attribute).then((res) => {
                console.log(res)
            }).catch((e) => {
                console.log(e)
            })
        }

        // nothing
        ps.push(postAPI(`/api/products/${this.state.id}`,
            {
                name: this.state.name,
                shelftimeNotice: this.state.shelftimeNotice,
                shelftimeAlert: this.state.shelftimeAlert,
                categoryId: this.state.selectedCategory === -1 ? null : this.state.selectedCategory,
                nutrition: this.state.nutrition,
                displayPriority: this.state.displayPriority,
            },
            'post').then((response) => {
                if (response.data.success) {
                    this.props.pushFlashMessage(new Message("Success", 'success'));

                } else {
                    window.alert(response.data.message);
                }
                return;
            }));

        Promise.all(ps).then(() => {
            console.log('all done');
            this.props.reloadAllProducts();
        });

        return false;
    }

    handleNameChange(event) {
        this.setState({ name: event.target.value });
    }

    handleProductAttribute1Change(event) {
        this.setState({ attribute: { ...this.state.attribute, attribute1: event.target.value } });
    }

    handleProductAttribute2Change(event) {
        this.setState({ attribute: { ...this.state.attribute, attribute2: event.target.value } });
    }

    handleProductAttribute3Change(event) {
        this.setState({ attribute: { ...this.state.attribute, attribute3: event.target.value } });
    }

    handleProductAttribute4Change(event) {
        this.setState({ attribute: { ...this.state.attribute, attribute4: event.target.value } });
    }

    handleDisplayPriorityChange(event) {
        let value = parseFloat(event.target.value) || 0;
        if (value < 0) {
            value = Math.abs(value);
        }
        this.setState({ displayPriority: value });
    }

    handleShelftimeNotice(event) {
        this.setState({ shelftimeNotice: event.target.value })
    }

    handleShelftimeAlert(event) {
        this.setState({ shelftimeAlert: event.target.value })
    }

    handleCategory(event) {
        this.setState({ selectedCategory: parseInt(event.target.value) })
    }

    handleNutrition(event) {
        this.setState({ nutrition: event.target.value })
    }

    onSelectFile(e) {
        console.log(e.target.files);
        if (e.target.files && e.target.files.length > 0) {
            console.log(e.target.files[0]);
            this.setState({ pictureFile: e.target.files[0] })
        } else {
            this.setState({ pictureFile: null })
        }

        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent: string = fileReader.result as string;
            this.pictureEncodedContent = window.btoa(pictureContent);
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    render() {
        let formulas = this.state.formulaList.map((formula) => {
            return <tr key={formula.ingredientProduct.id}>
                <td>
                    {formula.ingredientProduct.name}
                </td>
                <td>
                    {formula.consumeQuantity + ' ' + formula.ingredientProduct.productUnit}
                </td>
            </tr>
        })

        return (<section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Product Details</title>
            </Helmet>
            <h1>Product Details</h1>
            <Link to={"/products"}>&lt;Back</Link>

            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Product Name</label>
                            <input onChange={this.handleNameChange.bind(this)} type={"text"} value={this.state.name}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Product Picture (optional)</label>
                            <a rel="noreferrer" target={"_blank"} href={this.state.pictureUrl}>

                                <img src={this.state.pictureUrl}
                                    style={{ height: "2em" }}
                                    alt={`${this.state?.name}`} />
                            </a>
                            <input type="file" onChange={this.onSelectFile.bind(this)} className={"form-control"} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Shelftime Warning (Notice)</label>
                        <div className="row">
                            <div className="col-xs-11">
                                <div className="form-group">
                                    <input onChange={this.handleShelftimeNotice.bind(this)} type={"text"}
                                        value={this.state.shelftimeNotice ?? ''} className="form-control" />
                                </div>
                            </div>
                            <div className="col-xs-1" style={{ padding: "5px" }}>
                                <span>Days</span>
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-6">
                        <label>Shelftime Warning (Alert)</label>
                        <div className="row">
                            <div className="col-xs-11">
                                <div className="form-group">
                                    <input onChange={this.handleShelftimeAlert.bind(this)} type={"text"}
                                        value={this.state.shelftimeAlert ?? ''} className="form-control" />
                                </div>
                            </div>
                            <div className="col-xs-1" style={{ padding: "5px" }}>
                                <span>Days</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Category</label>
                            &nbsp;
                            <Link to="/categories">
                                More
                            </Link>
                            <select value={this.state.selectedCategory}
                                id="category"
                                name="category"
                                className="form-control"
                                onChange={this.handleCategory.bind(this)}>
                                <option value={-1}>No Category</option>
                                {this.state.categoriesList && this.state.categoriesList.map((category) => {
                                    return <option key={category.id} value={category.id}>{category.category}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Display Priority(The higher the value, the higher the priority)</label>
                            <input
                                onChange={this.handleDisplayPriorityChange.bind(this)}
                                type="number"
                                min="0"
                                value={this.state.displayPriority || 0}
                                className="form-control"
                            />
                        </div>
                    </div>

                </div>

                {this.state.formulaList.length > 0 && <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Formula</label>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Ingredient Name</th>
                                        <th scope="col">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formulas}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Nutrition</label>
                            <textarea onChange={this.handleNutrition.bind(this)}
                                value={this.state.nutrition ?? ''} className="form-control" placeholder={"input product nutrition"} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Attribute 1:</label>
                            <input onChange={this.handleProductAttribute1Change.bind(this)} type={"text"} value={this.state.attribute?.attribute1}
                                className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Attribute 2:</label>
                            <input onChange={this.handleProductAttribute2Change.bind(this)} type={"text"} value={this.state.attribute?.attribute2}
                                className="form-control" />
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Attribute 3:</label>
                            <input onChange={this.handleProductAttribute3Change.bind(this)} type={"text"} value={this.state.attribute?.attribute3}
                                className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Attribute 4:</label>
                            <input onChange={this.handleProductAttribute4Change.bind(this)} type={"text"} value={this.state.attribute?.attribute4}
                                className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <input type="submit" className="btn btn-success" value={"Save"} />
                </div>
            </form>
        </section>);
    }

}

const ProductDetail = withGlobalData(ProductDetailRaw);


export default ProductDetail;
