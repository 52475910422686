// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=js+dts"
// @generated from file telemetry/report/v1/api.proto (package telemetry.report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GenerateKioskForceOrderReportRequest, GenerateKioskForceOrderReportResponse, GetSummaryReportPreferenceRequest, GetSummaryReportPreferenceResponse, UpdateSummaryReportPreferenceRequest, UpdateSummaryReportPreferenceResponse } from "./api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service telemetry.report.v1.ReportService
 */
export const ReportService = {
  typeName: "telemetry.report.v1.ReportService",
  methods: {
    /**
     * @generated from rpc telemetry.report.v1.ReportService.GetSummaryReportPreference
     */
    getSummaryReportPreference: {
      name: "GetSummaryReportPreference",
      I: GetSummaryReportPreferenceRequest,
      O: GetSummaryReportPreferenceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc telemetry.report.v1.ReportService.UpdateSummaryReportPreference
     */
    updateSummaryReportPreference: {
      name: "UpdateSummaryReportPreference",
      I: UpdateSummaryReportPreferenceRequest,
      O: UpdateSummaryReportPreferenceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc telemetry.report.v1.ReportService.GenerateKioskForceOrderReport
     */
    generateKioskForceOrderReport: {
      name: "GenerateKioskForceOrderReport",
      I: GenerateKioskForceOrderReportRequest,
      O: GenerateKioskForceOrderReportResponse,
      kind: MethodKind.Unary,
    },
  }
};

