// @generated by protoc-gen-es v1.8.0 with parameter "target=js+dts"
// @generated from file telemetry/device/v1/api.proto (package telemetry.device.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum telemetry.device.v1.DeviceWorkingMode
 */
export const DeviceWorkingMode = /*@__PURE__*/ proto3.makeEnum(
  "telemetry.device.v1.DeviceWorkingMode",
  [
    {no: 0, name: "DEVICE_WORKING_MODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEVICE_WORKING_MODE_AUDITING", localName: "AUDITING"},
    {no: 2, name: "DEVICE_WORKING_MODE_CASHLESS_1", localName: "CASHLESS_1"},
    {no: 3, name: "DEVICE_WORKING_MODE_CASHLESS_2", localName: "CASHLESS_2"},
    {no: 4, name: "DEVICE_WORKING_MODE_COEXISTING", localName: "COEXISTING"},
    {no: 5, name: "DEVICE_WORKING_MODE_SILENCE", localName: "SILENCE"},
  ],
);

/**
 * @generated from enum telemetry.device.v1.DeviceMode
 */
export const DeviceMode = /*@__PURE__*/ proto3.makeEnum(
  "telemetry.device.v1.DeviceMode",
  [
    {no: 0, name: "DEVICE_MODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEVICE_MODE_MDB", localName: "MDB"},
    {no: 2, name: "DEVICE_MODE_PULSE", localName: "PULSE"},
  ],
);

/**
 * @generated from message telemetry.device.v1.ListDevicesRequest
 */
export const ListDevicesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.device.v1.ListDevicesRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message telemetry.device.v1.Device
 */
export const Device = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.device.v1.Device",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "imei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "working_mode", kind: "enum", T: proto3.getEnumType(DeviceWorkingMode) },
    { no: 5, name: "online", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "device_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "vending_machine_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "vending_machine_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "in_testing_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "latpay_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "signal_quality", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "device_mode", kind: "enum", T: proto3.getEnumType(DeviceMode) },
    { no: 15, name: "pulse_duration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message telemetry.device.v1.ListDevicesResponse
 */
export const ListDevicesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.device.v1.ListDevicesResponse",
  () => [
    { no: 1, name: "devices", kind: "message", T: Device, repeated: true },
    { no: 2, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message telemetry.device.v1.GetDeviceDetailRequest
 */
export const GetDeviceDetailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.device.v1.GetDeviceDetailRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message telemetry.device.v1.GetDeviceDetailResponse
 */
export const GetDeviceDetailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.device.v1.GetDeviceDetailResponse",
  () => [
    { no: 1, name: "device", kind: "message", T: Device },
  ],
);

/**
 * @generated from message telemetry.device.v1.UpdateDeviceRequest
 */
export const UpdateDeviceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.device.v1.UpdateDeviceRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "working_mode", kind: "enum", T: proto3.getEnumType(DeviceWorkingMode) },
    { no: 3, name: "device_mode", kind: "enum", T: proto3.getEnumType(DeviceMode) },
    { no: 4, name: "pulse_duration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message telemetry.device.v1.UpdateDeviceResponse
 */
export const UpdateDeviceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.device.v1.UpdateDeviceResponse",
  [],
);

