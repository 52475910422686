// @generated by protoc-gen-es v1.8.0 with parameter "target=js+dts"
// @generated from file telemetry/report/v1/api.proto (package telemetry.report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message telemetry.report.v1.GetSummaryReportPreferenceRequest
 */
export const GetSummaryReportPreferenceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.GetSummaryReportPreferenceRequest",
  [],
);

/**
 * @generated from message telemetry.report.v1.GetSummaryReportPreferenceResponse
 */
export const GetSummaryReportPreferenceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.GetSummaryReportPreferenceResponse",
  () => [
    { no: 1, name: "enable_monthly_transaction_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "last_monthly_transaction_report_time", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message telemetry.report.v1.UpdateSummaryReportPreferenceRequest
 */
export const UpdateSummaryReportPreferenceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.UpdateSummaryReportPreferenceRequest",
  () => [
    { no: 1, name: "enable_monthly_transaction_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message telemetry.report.v1.UpdateSummaryReportPreferenceResponse
 */
export const UpdateSummaryReportPreferenceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.UpdateSummaryReportPreferenceResponse",
  [],
);

/**
 * @generated from message telemetry.report.v1.GenerateKioskForceOrderReportRequest
 */
export const GenerateKioskForceOrderReportRequest = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.GenerateKioskForceOrderReportRequest",
  () => [
    { no: 1, name: "vmids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "start_time", kind: "message", T: Timestamp },
    { no: 3, name: "end_time", kind: "message", T: Timestamp },
    { no: 4, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message telemetry.report.v1.GenerateKioskForceOrderReportResponse
 */
export const GenerateKioskForceOrderReportResponse = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.GenerateKioskForceOrderReportResponse",
  () => [
    { no: 1, name: "orders", kind: "message", T: KioskForceOrder, repeated: true },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message telemetry.report.v1.KioskForceOrder
 */
export const KioskForceOrder = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.KioskForceOrder",
  () => [
    { no: 1, name: "vmid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "vending_machine_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_time", kind: "message", T: Timestamp },
    { no: 4, name: "authorised_time", kind: "message", T: Timestamp },
    { no: 5, name: "dispense_time", kind: "message", T: Timestamp },
    { no: 6, name: "complete_time", kind: "message", T: Timestamp },
    { no: 7, name: "line_items", kind: "message", T: LineItemStatus, repeated: true },
    { no: 8, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "gateway_reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "gateway_transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "total_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "total_paid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "payment_gateway", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "order_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "vending_machine_attribute_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "vending_machine_attribute_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "vending_machine_attribute_3", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "vending_machine_attribute_4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message telemetry.report.v1.LineItem
 */
export const LineItem = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.LineItem",
  () => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "sub_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "product_attribute_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "product_attribute_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "product_attribute_3", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "product_attribute_4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message telemetry.report.v1.LineItemStatus
 */
export const LineItemStatus = /*@__PURE__*/ proto3.makeMessageType(
  "telemetry.report.v1.LineItemStatus",
  () => [
    { no: 1, name: "line_item", kind: "message", T: LineItem },
    { no: 2, name: "dispensed_successfully", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "gateway_refund_reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

