// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=js+dts"
// @generated from file telemetry/device/v1/api.proto (package telemetry.device.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetDeviceDetailRequest, GetDeviceDetailResponse, ListDevicesRequest, ListDevicesResponse, UpdateDeviceRequest, UpdateDeviceResponse } from "./api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service telemetry.device.v1.DeviceManagementService
 */
export const DeviceManagementService = {
  typeName: "telemetry.device.v1.DeviceManagementService",
  methods: {
    /**
     * @generated from rpc telemetry.device.v1.DeviceManagementService.ListDevices
     */
    listDevices: {
      name: "ListDevices",
      I: ListDevicesRequest,
      O: ListDevicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc telemetry.device.v1.DeviceManagementService.GetDeviceDetail
     */
    getDeviceDetail: {
      name: "GetDeviceDetail",
      I: GetDeviceDetailRequest,
      O: GetDeviceDetailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc telemetry.device.v1.DeviceManagementService.UpdateDevice
     */
    updateDevice: {
      name: "UpdateDevice",
      I: UpdateDeviceRequest,
      O: UpdateDeviceResponse,
      kind: MethodKind.Unary,
    },
  }
};

