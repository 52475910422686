import React, { useEffect, useState } from 'react';
import { ManagementServiceApi } from './api';
import { VendingMachine } from './models';
import { V1GetMachineAttributesResponse } from './telemetry/vending_machine/v1/typescript-axios';

interface Props {
    vendingMachine: VendingMachine;
}

export function VendingMachinAttributePanel(props: Props) {

    const [machineAttributes, setMachineAttributes] = useState<V1GetMachineAttributesResponse | null>(null)

    useEffect(() => {
        loadMachineAttributes();
        // eslint-disable-next-line
    }, [props.vendingMachine])


    const loadMachineAttributes = () => {
        new ManagementServiceApi().machineManagementServiceGetMachineAttributes(props.vendingMachine.uuid).then(
            res => {
                setMachineAttributes(res.data)
            }).catch((e) => {
                alert("get machine attributes error " + e)
            });
    }

    const save = () => {
        new ManagementServiceApi().machineManagementServiceUpdateMachineAttributes(props.vendingMachine.uuid, {
            attribute1: machineAttributes?.attribute1,
            attribute2: machineAttributes?.attribute2,
            attribute3: machineAttributes?.attribute3,
            attribute4: machineAttributes?.attribute4,
        }).then(res => {
            alert("update machine attribute successfully ")
        }).catch((e) => {
            alert("fail to update machine attribute, err:" + e)
        });
    };

    return (
        <div className="well">
            <h2>Vending Machine Attributes</h2>
            <div className="form-group">
                <label>Attribute 1:</label>
                <input
                    type="text"
                    className="form-control"
                    value={machineAttributes?.attribute1 ?? ''}
                    onChange={(e) => setMachineAttributes({
                        ...machineAttributes, attribute1: e.target.value,
                    })}
                />
            </div>
            <div className="form-group">
                <label>Attribute 2:</label>
                <input
                    type="text"
                    className="form-control"
                    value={machineAttributes?.attribute2 ?? ''}
                    onChange={(e) => setMachineAttributes({
                        ...machineAttributes, attribute2: e.target.value,
                    })}
                />
            </div>
            <div className="form-group">
                <label>Attribute 3:</label>
                <input
                    type="text"
                    className="form-control"
                    value={machineAttributes?.attribute3 ?? ''}
                    onChange={(e) => setMachineAttributes({
                        ...machineAttributes, attribute3: e.target.value,
                    })}
                />
            </div>
            <div className="form-group">
                <label>Attribute 4:</label>
                <input
                    type="text"
                    className="form-control"
                    value={machineAttributes?.attribute4 ?? ''}
                    onChange={(e) => setMachineAttributes({
                        ...machineAttributes, attribute4: e.target.value,
                    })}
                />
            </div>
            <div className="form-group">
                <button className="btn btn-success" onClick={save}>
                    Save
                </button>
            </div>
        </div>
    );
}
