import React, {Component} from 'react';
import {getAPI} from "./api";
import {DeviceUptimeResponse} from "./models";
import {Link} from "react-router-dom";
import {Device} from "./connect-ts/telemetry/device/v1/api_pb";


interface Props {
    device: Device
}

interface State {
    uptime: DeviceUptimeResponse | null
}

export class DeviceUptime extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            uptime: null
        }
    }

    componentDidMount() {
        getAPI(`/api/telemetry_devices/${this.props.device.uuid}/uptime`).then((response) => {
            this.setState({'uptime': response.data});
        });
    }

    render() {
        if (this.state.uptime == null) {
            return (<span>-</span>);
        }
        return (
            <div>
                <ul>
                    <li>24-hour
                        uptime: {Math.min(1.0 * this.state.uptime.uptime24hrs / (24.0 * 3600 * 1000 / 100), 100.0).toFixed(2)}%
                    </li>
                    <li>7-day
                        uptime: {Math.min(1.0 * this.state.uptime.uptime7Day / (24.0 * 7 * 3600 * 1000 / 100), 100.0).toFixed(2)}%
                    </li>
                    <li>28-day
                        uptime: {Math.min(1.0 * this.state.uptime.uptime28Day / (24.0 * 28 * 3600 * 1000 / 100), 100.0).toFixed(2)}%
                    </li>

                </ul>

                <Link className="btn btn-info" to={`/devices/${this.props.device.uuid}/sessions`}>Session Details</Link>
            </div>
        )
    }

}